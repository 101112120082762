import './navbar.scss';
import classNames from 'classnames';
import { useEffect, useCallback, useState } from 'react';

import { ReactComponent as Logo } from '../logo.svg';

const menuItems = [
  { key: 'services', title: 'szolgáltatások' },
  { key: 'references', title: 'munkáim', withOffset: true },
  { key: 'about', title: 'rólam', withOffset: true },
  { key: 'contact', title: 'elérhetőségek', withOffset: true }
];

function NavBar()
{
  const [ headerTop, setHeaderTop ] = useState(null);
  const [ isHeaderFixed, setIsHeaderFixed ] = useState(false);
  const [ activeNavLinkId, setActiveNavLinkId ] = useState('header');
   

  const navRef = useCallback(node => {
    if (node !== null) {
      setHeaderTop(node.offsetTop);
    }
  }, []);
  
  const getViewPercentage = (element) => {
    const viewport = {
      top: window.pageYOffset,
      bottom: window.pageYOffset + window.innerHeight
    };
  
    const elementBoundingRect = element.getBoundingClientRect();
    const elementPos = {
      top: elementBoundingRect.y + window.pageYOffset,
      bottom: elementBoundingRect.y + elementBoundingRect.height + window.pageYOffset
    };

    if (viewport.top > elementPos.bottom || viewport.bottom < elementPos.top) {
      return 0;
    }
  
    if (elementPos.top < viewport.top && elementPos.bottom > viewport.bottom) {
      return 100;
    }
  
    const elementHeight = elementBoundingRect.height;
    let elementHeightInView = elementHeight;
  
    if (elementPos.top < viewport.top) {
      elementHeightInView = elementHeight - (window.pageYOffset - elementPos.top);
    }
  
    if (elementPos.bottom > viewport.bottom) {
      elementHeightInView = elementHeightInView - (elementPos.bottom - viewport.bottom);
    }
  
    const percentageInView = (elementHeightInView / window.innerHeight) * 100;
  
    return Math.round(percentageInView);
  };
  
  useEffect(() => {  
    const onScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsHeaderFixed(scrollTop >= headerTop);

      let activeItemKey = 'header';

      if (scrollTop > 100) {
        const sections = Array.from(document.getElementsByTagName('section'));
        const positions = [{key: 'header'}, ...menuItems]
          .map(x =>  ({
            key: x.key,
            top: Math.abs(sections.find(s => s.className === x.key).offsetTop - scrollTop),
            percent: getViewPercentage(sections.find(s => s.className === x.key))
          }))
          // .sort((a,b) => a.top - b.top);
          .sort((a,b) => b.percent - a.percent)
          ;

        activeItemKey = positions[0].key;
      }

      if(activeItemKey !== activeNavLinkId) {
        setActiveNavLinkId(activeItemKey);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [headerTop, activeNavLinkId, setActiveNavLinkId]);

  const handleClick = (key, withOffset) => {
    const element = document.getElementsByClassName(key)[0];
    if (withOffset === true)
    {
      window.scroll({ top: element.getBoundingClientRect().top + window.scrollY - 70, behavior: 'smooth' })
    }
    else
    {
      element.scrollIntoView({ behavior: 'smooth' });
    }
	};

  return (
    <header className={classNames({ fixed: isHeaderFixed })}>
      <nav ref={navRef}>
        <div className='logo' onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}>
          <Logo />
        </div>
        <ul>
          { menuItems.map(item => (
            <li
            key={item.key}
            id={item.key}
            className={activeNavLinkId === item.key ? 'active' : ''}
            onClick={()=> handleClick(item.key, item.withOffset)}
            >
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      </nav>
      <a href="https://facebook.com/faragovillany" target="_blank" className="facebook" rel="noreferrer"><i/></a>
      <a href="tel:+36309898973" target="_blank" className="phone" rel="noreferrer"><span>+36 30 989 8973</span></a>
    </header>
  );
}

export default NavBar;