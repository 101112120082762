import './services.scss';

function Services(){
  return (
    <section className='services'>
      <div className='container'>
        
        <div className="service">
          <div className="icon" />
          <p>Hibakeresés és javítás</p>
        </div>

        <div className="service">
          <div className="icon" />
          <p>Lakáselosztó cseréje, bővítése, korszerűsítése</p>
        </div>

        <div className="service">
          <div className="icon" />
          <p>Szerelvények cseréje, áthelyezése</p>
        </div>

        <div className="service">
          <div className="icon" />
          <p>Új kiállás kialakítása háztartási nagyfogyasztók részére</p>
          <p className='sub'>villanybojler, villanytűzhely (sütő, főzőlap), mosogatógép</p>
          {/* <ul>
            <li>villanybojler</li>
            <li>villanytűzhely (sütő, főzőlap)</li>
            <li>mosogatógép</li>
          </ul> */}
        </div>

        <div className="service">
          <div className="icon" />
          <p>Új hálózat kivitelezése</p>
          <p>Meglévő hálózat teljes vagy részleges felújítása, bővítése</p>
        </div>

      </div>
    </section>    
  );
}

export default Services;