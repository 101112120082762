import { StrictMode } from 'react';
import { hydrate, render } from "react-dom";
import React from "react" 
import App from './App';

import './normalize.css';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(<StrictMode><App /></StrictMode>, rootElement);
  // hydrate(<App />, rootElement);
} else {
	render(<StrictMode><App /></StrictMode>, rootElement);
  // render(<App />, rootElement);
}