import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './contact.scss';

const Input = ({ label, htmlId, type, isTextarea = false, values, errors, disabled, handleInputChange }) => {
  return (
    <div className={classNames('input', { 'has-error': Object.keys(errors).includes(htmlId) })}>
      { isTextarea
        ? <textarea placeholder=' ' value={values[htmlId]} onChange={handleInputChange.bind(this)} name={htmlId} key={htmlId} disabled={disabled} />
        : <input placeholder=' ' value={values[htmlId]} onChange={handleInputChange.bind(this)} type={type} name={htmlId} key={htmlId} disabled={disabled} />
      }
      <label htmlFor={htmlId}>{label}</label>
    </div>
  );
}

function Contact() {

  const [values, setValues] = useState({ name: '', email: '', phone: '', message: '' });
  const [errors, setErrors] = useState({ });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isValid = () => {    

    if (values.name.length === 0 && values.email.length === 0 && values.phone.length === 0 && values.message.length === 0) {
      return false;
    }

    if (values.name.length === 0) {
      setErrors({ name: 'Kötelező' });

      return false;
    }

    if (values.email.length === 0 && values.phone.length === 0) {
      setErrors({ email: '', phone: '' });

      return false;
    }

    if (values.email.length > 0 && !values.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
    {
      setErrors({ email: '', phone: '' });

      return false;
    }

    if (values.message.length === 0) {
      setErrors({ message: 'Kötelező' });

      return false;
    }

    setErrors({});

    return true;
  };

  useEffect(isValid, [values]);

  const onSubmit = (e) => {

    e.preventDefault();

    if (!isValid()  || isSubmitted) {
      return false;
    }

    let formData = new FormData(); 
    formData.append('name', values.name); 
    formData.append('email', values.email); 
    formData.append('phone', values.phone); 
    formData.append('message', values.message); 
    
    fetch('./contact.php', { 
      method: "POST", 
      body: JSON.stringify({
        name: values.name,
        email: values.email,
        phone: values.phone,
        message: values.message
      }), 
      headers: { "Content-Type": "application/json" } }
    )
    .then(response => { 
      setIsSubmitted(true);
    });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setValues({ ...values, [name]: value });
    isValid();
  }

  return (
    <section className='contact'>
      <div className='container'>
        <div className='contact-form'>
          <h4>Kérdése van?</h4>
          <h3>Vegye fel velem a kapcsolatot</h3>
          <form onSubmit={onSubmit.bind(this)} encType="multipart/form-data" method="post">
            <Input label="Név" type="text" htmlId="name" values={values} errors={errors} disabled={isSubmitted} handleInputChange={handleInputChange} />
            <Input label="E-mail cím" type="email" htmlId="email" values={values} errors={errors} disabled={isSubmitted} handleInputChange={handleInputChange} />
            <Input label="Telefonszám" type="tel" htmlId="phone" values={values} errors={errors} disabled={isSubmitted} handleInputChange={handleInputChange} />
            <Input label="Üzenet" htmlId="message" isTextarea values={values} errors={errors} disabled={isSubmitted} handleInputChange={handleInputChange} />
            { isSubmitted ? <span>Üzenet elküldve</span> : <button className='button'>Elküld</button> }
          </form>
        </div>
      </div> 
    </section>    
  );
}

export default Contact;