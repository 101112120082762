import './about.scss';

function About(){

  return (
    <section className='about'>
      <div className='container'>
        <div className="content-container">
          <h3>Faragó Bence vagyok, villanyszerelő</h3>
          <p>2018-ban végeztem el a villanyszerelői képzést, ahol - gyakorlati és elméleti oktatóimnak köszönhetően - nagyon jól sikerült elsajátítani a szakma alapjait. A képzést követően egy cég mindennapjaiba csöppentem, ahol kezdő villanyszerelőként nagyon sokat tanulhattam. 4 év alkalmazotti pozícióban eltöltött tevékenykedés során, a különböző munkaterületeken, el tudtam sajátítani a szakmának azon oldalát, amelyet az iskolában, tantermi körülmények között nem lehet megtanítani. Szembesültem valódi fizikai nehézségekkel, amiket az adott cél eléréséhez meg kellett oldani. Ezzel párhuzamosan fejlődött a problémamegoldó képességem is, a találékonyságon kívül.</p>
          <p>Megtapasztaltam, hogyan lehet és kell dolgozni az építőiparban, hogy az hatékony és gyors lefolyású legyen.</p>
          <p>Már kezdőként fejembe vettem, hogy egyszer vállalkozó szeretnék lenni. Saját szájízem szerint alakítani vállalkozásomat, jövőmet, terveimet.  Mindemellett szakmailag tovább fejlődni és nyitni a szakma által kínált innovatív, új dolgokra.</p>
          <p>2021 év végén azt éreztem, hogy kellő tudással rendelkezem ahhoz, hogy vállalkozóként tevékenykedjek, 2022 februárjában egyéni vállalkozó lettem.</p>
          <p>Bizakodóan tekintek a jövőbe, remélem, minél több ügyfélnek tudok segíteni szaktudásommal, ezzel biztonságossá, kényelmesebbé és szerethetővé téve otthonát, villamos hálózatát.</p>
        </div>
        <div className='image-container'>
          <div className='image' />
        </div>
      </div>
    </section>    
  );
}

export default About;