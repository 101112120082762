import { Gallery, Item } from "react-photoswipe-gallery";
import PhotoAlbum from "react-photo-album";

import 'photoswipe/dist/photoswipe.css'
import './references.scss';
import classNames from "classnames";
import { useRef, useState } from "react";

const Photo = ({ photo, imageProps, wrapperProps }) => {
  const { width, height, alt } = photo;
  const { src } = imageProps;

  return (      
    <Item
      key={src}
      original={`./img/gallery/${src}`}
      thumbnail={`./img/gallery/thumbs/${src}`}
      width={width}
      height={height}
    >
      {({ ref, open }) => (
        <div className="img-container" style={{...imageProps.style, display: 'flex'}}>
          <img ref={ref} src={`./img/gallery/thumbs/${src}`} alt={alt} onClick={open} />
        </div>
      )}
    </Item>
  );
};

function References(){

  const [ isCollapsed, setIsCollapsed ] = useState(true);
  const [ maxHeight, setMaxHeight ] = useState(0);
  const galleryRef = useRef(null);

  const images = [
    { src: '20200131_141613.jpg', width: 627, height: 1280 },
    { src: '20200222_161748.jpg', width: 1280, height: 526 },
    // { src: '20200222_161805.jpg', width: 1280, height: 1271 },
    { src: '20220506_162326.jpg', width: 960, height: 1280 },
    { src: '20220311_092802.jpg', width: 1280, height: 1015 },
    { src: '20201126_151431.jpg', width: 960, height: 1280 },
    { src: '20200724_140313.jpg', width: 960, height: 1280 },
    { src: '20201126_190434.jpg', width: 960, height: 1280 },
    { src: '20200307_144445.jpg', width: 1280, height: 536 },
    { src: '20201126_191036.jpg', width: 960, height: 1280 },
    { src: '20201218_164036.jpg', width: 960, height: 1280 },
    { src: '20201218_164233.jpg', width: 1280, height: 780 },
    { src: '20210928_144843.jpg', width: 1280, height: 598 },
    { src: '20220210_134335.jpg', width: 960, height: 1280 },
    { src: '20220311_092815.jpg', width: 960, height: 1280 },
    { src: '20220311_092840.jpg', width: 960, height: 1280 },
    { src: '20210928_144847.jpg', width: 1059, height: 1280 },
    { src: '20220411_171558.jpg', width: 1280, height: 960 },
    { src: '20220419_152116.jpg', width: 960, height: 1280 },
    { src: '20220411_171701.jpg', width: 960, height: 1280 },
    { src: '20220419_153021.jpg', width: 960, height: 1280 },
    { src: '20220420_095426.jpg', width: 1280, height: 960 },
    { src: '20220420_095459.jpg', width: 960, height: 1280 },
    // { src: '20220420_100840.jpg', width: 960, height: 1280 },
    { src: '20220421_120458.jpg', width: 960, height: 1280 },
    { src: '20220422_134438.jpg', width: 1280, height: 960 },
    { src: '20220422_134731.jpg', width: 1280, height: 960 },
    { src: '20220422_135131.jpg', width: 1280, height: 960 },
    { src: '20220422_135309.jpg', width: 960, height: 1280 },
    { src: '20220422_140139.jpg', width: 960, height: 1280 },
    // { src: '272525091_1005652200161394_2805960146700136128_n.jpg', width: 1280, height: 576 },
    { src: '20220504_155918.jpg', width: 1280, height: 960 }
  ];

  const keywords = 'villanyszerelés,villanyszerelő,gyors,olcsó,hétvégén,megbízható,pontos,precíz,azonnal,sos villanyszerelés,villanykapcsoló,csere,szerelés,üzembe helyezés,konnektor,kapcsoló,dugalj,lámpa,led,led szalag,csengő,kaputelefon,tűzhely,villanytűzhely,főzőlap,indukciós,indukciós főzőlap,kerámia főzőlap,sütő,páraelszívó,bojler,elosztószekrény,lakáselosztó,korszerűsítés,kismegszakító,biztosíték,Pest megye,Budapest,Dunakeszi,Mátyásföld,Árpádföld,Cinkota,Újpest,Újpalota,Rákosszentmihály,Csömör,Nagytarcsa,Malomdomb,Pécel,Gödöllő,XVI. kerület,XVII. kerület,XIV. kerület,XV. kerület,XIII. kerület,XVIII. kerület,X. kerület,IV. kerület'
    .replace(/,/g, ', ')
    .match(/[^,]+,[^,]+/g);

  images.forEach((img, index) => {
    img.alt = keywords[index % keywords.length].trim();
  });

  return (
    <section className='references'>
      <div ref={galleryRef} className={classNames('gallery', { collapsed: isCollapsed })} style={{ maxHeight: maxHeight }}>
        <Gallery options={{bgOpacity: 0.9}}>
          <PhotoAlbum
            layout="rows"
            photos={images}
            spacing={0}
            renderPhoto={Photo}
          />
        </Gallery>
        <button className="button" onClick={() => { setMaxHeight(galleryRef.current.scrollHeight); setIsCollapsed(false); }}>Mutass többet</button>
      </div>
    </section>    
  );
}

export default References;