import Loader from './components/Loader/Loader';
import NavBar from './components/NavBar/NavBar';
import References from './components/Sections/References';
import Header from './components/Sections/Header';
import Services from './components/Sections/Services';
import About from './components/Sections/About';

import './App.scss';
import Contact from './components/Sections/Contact';

function App() {
  return (
    <>
      <Loader />
      <Header />
      <NavBar />
      <Services />      
      <References />      
      <About />
      <Contact />
    </>
  );
}

export default App;
