import { useEffect, useState } from 'react';
import './loader.scss';
import classNames from 'classnames';


function Loader()
{
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ isVisible, setIsVisible ] = useState(true);

  const setLoaded = () => {
    setIsLoaded(true);
    setTimeout(() => setIsVisible(false), 2000);
  };
  
  useEffect(() => {
    window.addEventListener('load', setLoaded);

    return () => {
      window.removeEventListener('load', setLoaded);
    }
  }, []);

  if (!isVisible) {
    return <></>;
  }

  return (
    <div className={classNames('loader', { loaded: isLoaded })}>
      <div>
          <div></div>
          <div></div>
          <div></div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
              <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                  <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"/>
              </filter>
          </defs>
      </svg>
    </div>
  );
}

export default Loader;