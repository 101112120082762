import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
// import ReactTypingEffect from 'react-typing-effect';

import './header.scss';
import { ReactComponent as Logo } from '../logo.svg';
import { ReactComponent as Map } from '../budapest.svg';

function Header() {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <section className='header'>
        <Particles
          className="animation"
          init={particlesInit}
          options={{
            fpsLimit: 60,
            interactivity: {
              events: {
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                repulse: {
                  distance: 100,
                  duration: 1.2,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.1,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 2, max: 5 },
              },
            },
            detectRetina: true,
            fullScreen: false
          }}
        />
        <div className="container">
          <div className="yellow-square" />
          <Map />
          <div className='logo'>
            <Logo />
            <h1>Faragó Villany</h1>
          </div>
          <div className="content">        
            <h2>
              {/* <ReactTypingEffect
                speed={200}
                typingDelay={50}
                eraseDelay={2000}
                eraseSpeed={50}
                text={['Precizitás', 'Pontosság', 'Megbízhatóság']}
              /> */}
              <span>Precizitás</span>
              <span>Pontosság</span>
              <span>Megbízhatóság</span>
            </h2>
          </div>
        </div>
      </section>
  )
}

export default Header;